var $ = require("jquery");
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const isLargeScreen = window.matchMedia("(min-width: 1025px)").matches;
const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;

document.querySelectorAll('[data-splitting="words"]').forEach(function(heading) {
  gsap.delayedCall(.5, animateWords);
  var duration = 1.4;
  var stagger = 0.05;

  function animateWords() {
    gsap.to(heading.querySelectorAll(".word"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "power4.out",
      opacity: 1,
      duration: duration,
      stagger: stagger
    });

    gsap.to(heading.querySelectorAll(".word span"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      y: 0,
      ease: "power4.out",
      duration: duration,
      stagger: stagger
    });
  };
});

const aboutIntroImages = document.querySelectorAll('.about-intro__image');
if (aboutIntroImages.length) {
  gsap.utils.toArray(aboutIntroImages).forEach((image, index) => {
    const yStart = 50 + index * 150;
    const yEnd = 0;

    gsap.fromTo(
      image,
      { y: yStart },
      {
        y: yEnd,
        scrollTrigger: {
          trigger: ".about-intro .container",
          start: "top 70%",
          end: "bottom 60%",
          scrub: 1 + index,
        },
      }
    );
  });
}

const solutionsIntroImages = document.querySelectorAll(".solutions-intro img");
if (solutionsIntroImages.length) {
  solutionsIntroImages.forEach((element) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "center center",
        once: true,
        scrub: 2,
      },
      y: 100,
      duration: 1.2,
      ease: "power2.out",
    });
  });
}

const caseCtaArrow = document.querySelector('.case-study-cta__arrow');
if (caseCtaArrow) {
  gsap.from(caseCtaArrow, {
    y: '40%',
    scrollTrigger: {
      trigger: caseCtaArrow,
      start: "top bottom",
      end: "bottom bottom",
      scrub: 3,
    },
  });
}

const childPageCards = document.querySelectorAll(".child-page-grid__grid .child-page-card");
if (childPageCards.length) {
  childPageCards.forEach((element, index) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%",
        end: "bottom 80%",
        once: true,
        scrub: 1 + index,
      },
      y: 200,
      opacity: 0,
      duration: 1.2,
      ease: "power2.out",
    });
  });
}
if(isSmallScreen) {
 const imageTextImages = document.querySelectorAll(".image-text:not(.image-text--template-3) img");
  if (imageTextImages.length) {
    imageTextImages.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
          scrub: 1,
        },
        y: 100,
        ease: "power2.out",
      });
    });
  }
}

if(isLargeScreen) {
  window.addEventListener('load', function() {
    setTimeout(function() {
      if (isLargeScreen && document.querySelector('.footer')) {
        gsap.to('.footer', {
          scrollTrigger: {
            trigger: '.footer-pusher',
            start: 'top bottom',
            end: '+=80%',
            scrub: true,
          },
          y: '0',
          duration: 1,
          ease: 'linear',
        });
      }
    }, 200);

    const resizeObserver = new ResizeObserver(() => {
      ScrollTrigger.refresh();
    });

    resizeObserver.observe(document.body);
  });

  const heroBg = document.querySelector('.hero__bg');
  if (heroBg) {
    gsap.to(heroBg, {
      y: '40%',
      scale: 1.1,
      scrollTrigger: {
        trigger: '.hero',
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const hero__content = document.querySelector('.hero__content');
  if (hero__content) {
    gsap.to(hero__content, {
      y: '80%',
      opacity: 0,
      scrollTrigger: {
        trigger: '.hero',
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const videoFull = document.querySelector('.video--full video');
  if (videoFull) {
    gsap.from(videoFull, {
      y: '-40%',
      scrollTrigger: {
        trigger: '.video--full',
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }

  const imageTextImages = document.querySelectorAll(".image-text:not(.image-text--template-3) img");
  if (imageTextImages.length) {
    imageTextImages.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 100%",
          end: "center center",
          once: true,
          scrub: 1.5,
        },
        y: 200,
        duration: 1.2,
        ease: "power2.out",
      });
    });
  }

  const xtg_image = document.querySelector('.xtg-cta__image img');
  if (xtg_image) {
    gsap.from(xtg_image, {
      y: '-30%',
      ease: 'none',
      scrollTrigger: {
        trigger: '.xtg-cta',
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const template_3_image = document.querySelector('.image-text--template-3 img');
  if (template_3_image) {
    gsap.from(template_3_image, {
      y: '-20%',
      ease: 'none',
      scrollTrigger: {
        trigger: '.image-text--template-3',
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const child_page_grid_arrow = document.querySelector('.child-page-grid__arrow');
  if (child_page_grid_arrow) {
    gsap.from(child_page_grid_arrow, {
      y: '150px',
      x: '-150px',
      ease: 'none',
      scrollTrigger: {
        trigger: '.child-page-grid',
        start: "top bottom",
        end: "top+=200", // 200px after the start of the trigger
        scrub: 1.5,
      },
    });
  }
}
