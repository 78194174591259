var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
// Get the pagination texts from the data attribute
if($('.customer-journey-carousel__slider').length) {
    const paginationTexts = JSON.parse(document.querySelector('.swiper-pagination').dataset.paginationTexts);
    var customerJourneySlider = new Swiper('.customer-journey-carousel__slider', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        threshold: 1,
        speed: 1000,
         autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.customer-journey-carousel .swiper-button-next',
            prevEl: '.customer-journey-carousel .swiper-button-prev',
        },
        pagination: {
            el: '.customer-journey-carousel .swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                const text = paginationTexts[index] || `Step ${index + 1}`;
                return `<span class="${className}">${text}</span>`;
            },
        },
        breakpoints: {
            650: {
                spaceBetween: 16,
                slidesPerView: 1.5,
            },
            1025: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            1200: {
                slidesPerView: 2.37,
                spaceBetween: 25,
            },
        },
    });

     // Add a 1-second delay before autoplay starts
    setTimeout(() => {
        customerJourneySlider.autoplay.start();
    }, 1000);
}

if($('.base-slider').length) {
    $('.base-slider').each(function () {
        var $sliderContainer = $(this);
        var $sliderElement = $sliderContainer.find('.base-slider__slider');
        var $slides = $sliderElement.find('.swiper-slide');

        new Swiper($sliderElement[0], {
            slidesPerView: 1,
            spaceBetween: 16,
            loop: $slides.length > 3,
            threshold: 1,
            speed: 1000,
            navigation: {
                nextEl: $sliderContainer.find('.swiper-button-next')[0],
                prevEl: $sliderContainer.find('.swiper-button-prev')[0],
            },
            pagination: {
                el: $sliderContainer.find('.swiper-pagination')[0],
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' + currentClass + '"></span>' +
                        '<span class="divider"></span>' +
                        '<span class="' + totalClass + '"></span>'
                    );
                }
            },
            breakpoints: {
            650: {
                slidesPerView: 1.5,
            },

            1025: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            1200: {
                slidesPerView: 2.37,
                spaceBetween: 25,
            },
        },
        });
    });
}

if($('.case-study-links__grid').length) {
    var $sliderContainer = $('.case-study-links');
    var $sliderElement = $sliderContainer.find('.swiper');

    new Swiper($sliderElement[0], {
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        threshold: 1,
        speed: 1000,
        navigation: {
            nextEl: $sliderContainer.find('.swiper-button-next')[0],
            prevEl: $sliderContainer.find('.swiper-button-prev')[0],
        },
        pagination: {
            el: $sliderContainer.find('.swiper-pagination')[0],
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' +
                    '<span class="divider"></span>' +
                    '<span class="' + totalClass + '"></span>'
                );
            }
        },
        breakpoints: {
            650: {
                spaceBetween: 16,
                slidesPerView: 1.75,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            1025: {
                slidesPerView: 3,
                spaceBetween: 25,
            },
        },
    });
}
